<div
  id="app"
  class="h-100 app"
  [ngClass]="settings.theme"
  [dir]="settings.rtl ? 'rtl' : 'ltr'"
>
  <router-outlet></router-outlet>
	<ngx-spinner
		bdColor="rgba(0, 0, 0, 0.8)"
		size="medium"
		color="#fff"
		type="ball-scale-multiple"
		[fullScreen]="true"
	></ngx-spinner>
  <app-msg-banner></app-msg-banner>
</div>
