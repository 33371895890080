import { Component, HostListener } from '@angular/core';
import { AppSettings, Settings } from './app.settings';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { LoadingService } from './core/services/loading.service';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  LogLevel,
  Logger,
} from '@azure/msal-browser';
import { Location } from '@angular/common';
import { User } from './core/models/user.model';
import { GraphService } from './core/services/graph.service';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';

type IdTokenClaims = {
  preferred_username: string;
  name: string;
  groups: string[];
  roles: string[];
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Photo Capture Dashboard';
  public settings: Settings;

  isIframe: boolean;
  private loginSubscription: Subscription;
  private tokenSubscription: Subscription;
  loginDisplay: boolean;
  user: User = {
    email: '',
    displayName: '',
    groupIDs: [],
    profilePhotoUrl: null,
    currentPath: '',
    roles: [],
  };
  isLoading: Subject<boolean> = this.loadingService.isLoading;

  public showBackToTop = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loadingService: LoadingService,
    private router: Router,
    private graphService: GraphService,
    private dialog: MatDialog,
    private location: Location,
    private appSettings: AppSettings
  ) {
    this.isIframe = window !== window.parent && !window.opener;
    this.settings = this.appSettings.settings;

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    const acct = this.authService.instance.getAllAccounts();
    if (!acct || acct?.length === 0) {
      let redirectUrl: string = this.location.path();
      if (redirectUrl.includes('/landing') || !redirectUrl) {
        this.router.navigate(['/landing']);
      }
    }

    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.authService.handleRedirectObservable().subscribe((response) => {
      let redirectUrl: string = this.location.path();
      if (redirectUrl.includes('/landing') && response != null) {
        this.router.navigateByUrl('/');
      }
    });

    this.loginSubscription = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        console.log('login success ' + JSON.stringify(payload));

        this.authService.instance.setActiveAccount(payload.account);
        let idTokenClaims = payload.idTokenClaims as IdTokenClaims;
        this.graphService.user.email = idTokenClaims?.preferred_username;
        this.graphService.user.displayName = idTokenClaims?.name;
        this.graphService.loadProfilePhoto();

        if (idTokenClaims?.groups) {
          this.graphService.user.groupIDs = <string[]>(
            (<unknown>idTokenClaims.groups)
          );
        }
        if (idTokenClaims?.roles) {
          this.graphService.user.roles = <string[]>(
            (<unknown>idTokenClaims.roles)
          );
        }

        this.user = this.graphService.user;
        this.setLoginDisplay();
      });

    function loggerCallback(logLevel: LogLevel, message: string) {
      console.debug('client logging' + message);
    }

    this.authService.setLogger(
      new Logger({
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: true,
      })
    );

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        )
      )
      .subscribe((result: EventMessage) => {
        console.log('Acquire Token Failure callback');
        const payload = result.payload as AuthenticationResult;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          disableClose: true,
          data: {
            title: 'Confirm Action',
            message:
              'An authentication exception occurred. You need to log out of your American Greetings account and then log back in.',
            confirmationRequired: false,
          },
        });
        dialogRef.afterClosed().subscribe((dialogResult) => {
          if (dialogResult) {
            this.logout();
            return;
          }
        });
      });

    this.tokenSubscription = this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        console.debug('Acquire Token Success callback', payload.accessToken);

        if (this.user.displayName.length === 0) {
          let idTokenClaims = payload.idTokenClaims as IdTokenClaims;
          this.graphService.user.email = idTokenClaims?.preferred_username;
          this.graphService.user.displayName = idTokenClaims?.name;
          this.graphService.loadProfilePhoto();

          if (idTokenClaims?.groups) {
            this.graphService.user.groupIDs = <string[]>(
              (<unknown>idTokenClaims.groups)
            );
          }
          if (idTokenClaims?.roles) {
            this.graphService.user.roles = <string[]>(
              (<unknown>idTokenClaims.roles)
            );
          }

          this.user = this.graphService.user;
        }
      });

    if (this.user.displayName.length === 0 && this.loginDisplay) {
      const accounts = this.authService.instance.getAllAccounts();

      if (accounts.length > 0) {
        this.graphService.user.email =
          accounts[0].idTokenClaims['preferred_username'];
        this.graphService.user.displayName = accounts[0].idTokenClaims['name'];
        this.graphService.loadProfilePhoto();

        if (accounts[0].idTokenClaims['groups']) {
          this.graphService.user.groupIDs = <string[]>(
            (<unknown>accounts[0].idTokenClaims['groups'])
          );
        }
        if (accounts[0].idTokenClaims['roles']) {
          this.graphService.user.roles = <string[]>(
            (<unknown>accounts[0].idTokenClaims['roles'])
          );
        }

        console.log(this.graphService.user);
        this.user = this.graphService.user;
      }
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: 'landing',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this._destroying$.next(null);
    this._destroying$.complete();
    if (this.loginSubscription) {
      console.log('Unsubscribe Login Subscription');
      this.loginSubscription.unsubscribe();
    }
    if (this.tokenSubscription) {
      console.log('Unsubscribe Token Subscription');
      this.tokenSubscription.unsubscribe();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    $event.target.documentElement.scrollTop > 100
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
  }

  public goToTop() {
    const elmnt = document.getElementById('top');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }
}
