import { Menu } from '../models/menu.model';

export enum MenuItemsEnum {
	PhotoCaptureDashboard = 10
}

export const menuItems = [
	new Menu(
		MenuItemsEnum.PhotoCaptureDashboard,
		`Photo Capture`,
		'/photo-capture',
		null,
		'find_in_page',
		null,
		null,
		false,
		0
	)
];
