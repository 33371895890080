import { UntypedFormGroup } from '@angular/forms';
import { isMoment } from 'moment';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateTime } from 'luxon';
import { UserInterfaceMessageService } from '../../shared/components/msg-banner/services/user-interface-message.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private messageService: UserInterfaceMessageService) {}

  public static DATE_FORMATS = [
    'YYYY-MM-DDTHH:mm:ss.SSS[Z]', // Date with time and UTC timezone
    'YYYY-MM-DDTHH:mm:ss.SSS', // Date with time but no timezone
    'YYYY-MM-DD HH:MM:SS',
    'YYYY-MM-DD', // Date with no time and no timezone
    'MM/DD/YYYY', // Date with no time and no timezone (format 1)
    'DD/MM/YYYY', // Date with no time and no timezone (format 2)
    'YYYY-MM-DD', // Date with no time and no timezone (format 3)
    'DD-MM-YYYY', // Date with no time and no timezone (format 4)
    'YYYY-MM-DDTHH:mm:ss.SSSZ', // Date with time and UTC timezone with no separator between time and timezone
  ];

  // for creating/updating/sending to server purpose
  public static dateToServerDate(dateTime: any) {
    if (!dateTime) return null;
    const localDate = new Date(dateTime);
    const luxonDate = DateTime.fromJSDate(localDate);
    const offset = luxonDate.offset;
    const newDate = luxonDate.plus({minutes: offset}).toJSDate()
    return newDate;
  }

  // for displaying purpose
  public static dateFromServer(dateTime: any) {
    const offset = new Date(dateTime).getTimezoneOffset();
    const serverDate = new Date(dateTime);
    const newDate = moment(serverDate).add(offset, 'm').toDate();
    return newDate;
  }

  public static dateMomentToString(date: any, endOfTheDay: boolean = false) {
    if (endOfTheDay) date = this.endOfTheDay(date);

    if (isMoment(date) || date instanceof Date) {
      return JSON.stringify(date).replace(/['"]+/g, '');
    }
    return date;
  }

  public static endOfTheDay(date: any) {
    if (date && isMoment(date)) date = date.clone().endOf('day');
    if (date instanceof Date) date.setUTCHours(23, 59, 59, 999);

    return date;
  }

  public static startOfTheDay(date: any) {
    if (date && isMoment(date)) date = date.clone().startOf('day');
    if (date instanceof Date)
      this.dateToServerDate(date.setUTCHours(0, 0, 0, 0));

    return date;
  }

  public static arrayEquals(a: any, b: any) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  public static onFormFilterValueChange(
    searchForm: UntypedFormGroup,
    searchFormTable: UntypedFormGroup,
    currentSearchCriteria: any
  ) {
    let filterHasChange = Object.keys(searchForm.value).some((key) => {
      let result = false;
      if (
        (searchForm.value[key] == null || searchForm.value[key].length == 0) &&
        (currentSearchCriteria.searchForm[key] == undefined ||
          currentSearchCriteria.searchForm[key]?.length == 0)
      ) {
        result = false;
      } else if (Array.isArray(searchForm.value[key])) {
        result = !UtilsService.arrayEquals(
          searchForm.value[key],
          currentSearchCriteria.searchForm[key]
        );
      } else {
        result = searchForm.value[key] != currentSearchCriteria.searchForm[key];
      }
      return result;
    });

    if (filterHasChange) {
      searchFormTable.controls['customerNumbers'].disable();
      searchFormTable.controls['soldToGroupCustomerName'].disable();
    } else {
      searchFormTable.controls['customerNumbers'].enable();
      searchFormTable.controls['soldToGroupCustomerName'].enable();
    }
  }

  public static isDate(value: any): boolean {
    if (value instanceof Date) {
      if (!isNaN(value.getTime())) {
        return this.checkDateFormat(value);
      }
      return false;
    } else if (typeof value === 'string') {
      const parsedDate = Date.parse(value);
      if (!isNaN(parsedDate)) {
        return this.checkDateFormat(value);
      }
      return false;
    }
    return false;
  }

  public parseDate(date: Date | string): Date {
    const parsedDate = moment(date, UtilsService.DATE_FORMATS, true);
    return parsedDate.toDate();
  }

  private static checkDateFormat(date: Date | string): boolean {
    const parsedDate = moment(date, UtilsService.DATE_FORMATS, true);
    return parsedDate.isValid();
  }

  public static headerProperty(key: string) {
    let newKey = key.substring(0, 1).toUpperCase() + key.substring(1);
    newKey = newKey.match(/[A-Z0-9][a-z]*/g).join(' ');

    return newKey;
  }
}
